.blog-details-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .blog-content ol {
    padding-left: 20px;
    list-style-type: decimal;
    margin: 20px 0;
  }
  
  .blog-content li {
    margin-bottom: 10px;
    line-height: 1.6;
    font-size: 1.125rem;
    color: #444;
  }

  
  .blog-title {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .blog-meta {
    font-size: 1rem;
    color: #777;
    margin-bottom: 20px;
  }
  
  .tags {
    margin-bottom: 20px;
  }
  
  .tag {
    display: inline-block;
    background-color: #e0e0e0;
    color: #555;
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 0.875rem;
  }
  
  .blog-content {
    font-size: 1.125rem;
    line-height: 1.6;
    color: #444;
  }
  