.footer {
    background-color: #2a4c09;
    color: #fff;
    padding: 10px 0;
    bottom: 0;
    width: 100%;
    text-align: center;
  }
  .footer-links ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .footer-links ul li {
    margin-bottom: 10px; /* Adds spacing between links */
  }
  
  .footer-links ul li a {
    color: white;
    text-decoration: none;
    font-size: 16px;
  }
  
  .footer-links ul li a:hover {
    text-decoration: underline; /* Optional: underline on hover */
  }
  
  .footer h3 {
    color: white;
  }
  