.tours-container {
  display: flex;
  margin-top: 20px;
}

.sidebar {
  padding: 20px;
  background-color: #f8f9fa;
  border-right: 1px solid #dee2e6;
  height: 100vh;
  position: sticky;
  top: 0;
}

.filter-group {
  margin-bottom: 20px;
}

.filter-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.filter-group input,
.filter-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.destination-section {
  margin-top: 20px;
}

.destination-name {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.tour-card {
  transition: transform 0.3s;
}

.tour-card:hover {
  transform: translateY(-5px);
}

.card-image {
  height: 180px;
  object-fit: cover;
}

.card-body {
  padding: 15px;
}

.card-title {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.card-text {
  margin-bottom: 15px;
}

.btn-success {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
}

.btn-success:hover {
  background-color: #218838;
  border-color: #1e7e34;
}
.card-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}