.header {
  width: 100%;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: #fafafa;
  background-color: #2a4c09;
  border-bottom: 1px solid #2a4c09;
}

.contact-info {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  height: 50px;
}

.main-nav {
  background-color: #2a4c09;
  padding: 10px 20px;
}
.main-nav h3 {
  color: #fafafa;
}

.main-nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.main-nav ul li {
  margin: 0 15px;
}

.main-nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.main-nav ul li a:hover {
  text-decoration: underline;
}
