.contact {
  padding: 20px;
}

.primary-bg {
  background-color: #2a4c09;
  color: white;
  padding: 20px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.contact h1, .contact h2 {
  text-align: center;
}

.contact p {
  text-align: center;
  margin: 5px 0;
}

.contact-form {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button[type="submit"] {
  background-color: #2a4c09;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

button[type="submit"]:hover {
  background-color: #2a4c09;
}

.iframe-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
