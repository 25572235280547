.package-details {
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .package-banner {
    height: 300px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .package-title {
    font-size: 2.5em;
    margin: 0;
  }
  
  .package-content {
    padding: 20px;
  }
  
  .package-overview h2 {
    margin-top: 0;
  }
  
  .package-price {
    font-size: 1.8em;
    color: #2a4c09;
    margin-top: 10px;
  }
  
  .package-details-sections {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .inclusions, .exclusions {
    width: 48%;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .inclusions h3, .exclusions h3 {
    margin-top: 0;
    color: #5cb85c;
  }
  .package-booking h3 {
    margin-top: 0;
    padding-bottom: 5;
  }
  
  .booking-form {
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .submit-button {
    background-color: #5cb85c;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    width: 100%;
  }
  
  .submit-button:hover {
    background-color: #4cae4c;
  }
  
  .package-not-found {
    text-align: center;
    font-size: 1.5em;
    color: #d9534f;
    margin-top: 50px;
  }
  