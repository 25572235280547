.blog-list {
  padding: 20px;
}

/* Animation for the cards */
.animated-card {
  transition: transform 0.3s ease-in-out;
}

.animated-card:hover {
  transform: translateY(-10px) scale(1.02); /* Lift and scale the card on hover */
}

.title{
  font-size: 2.5em;
  margin-bottom: 10px;
  color: #2a4c09;
}
/* Title styling */
.title-blog {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #2a4c09;
}

/* Image styling */
.blog-card-img {
  height: 200px; /* Set a fixed height for the image */
  object-fit: cover; /* Make sure the image covers the area */
  border-top-left-radius: calc(0.25rem - 1px); /* Match Card border-radius */
  border-top-right-radius: calc(0.25rem - 1px); /* Match Card border-radius */
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.blog-details {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.blog-details:hover {
  transform: translateY(-5px);
}

.blog-details h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #007b5e;
}

.blog-details p {
  color: #555;
  font-size: 1em;
}

.read-more-btn {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #007b5e;
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.read-more-btn:hover {
  background-color: #005f46;
}
