body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1 {
  color: #fafafa;
}

.App {
  text-align: center;
}
