/* Hero Section */
.hero {
  position: relative;
  width: 100%;
  height: 60vh;
  overflow: hidden;
}


.hero-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

/* About Us Section */
.about-section {
  background-color: #f8f9fa;
}

.about-image {
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

/* Destinations Section */
.destination-card {
  width: 300px;
  border-radius: 10px;
  overflow: hidden;
  color: #ddd;
  transition: all 0.3s ease;
}

.card-link {
  text-decoration: none;
  color: inherit;
}

.card-image {
  position: relative;
}

.card-overlay {
  position: absolute;
  bottom: 0;
  background: rgba(87, 86, 86, 0.6);
  width: 100%;
  text-align: center;
  color: black;
  padding: 10px;
}

.destinations .card-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
}

.card-packages {
  font-size: 1rem;
  color: #fff;
}

/* Special Packages Section */
.special-packages .team-item {
  position: relative;
}

.special-packages .team-item img {
  transition: transform 0.3s ease;
}

.special-packages .team-item:hover img {
  transform: scale(1.05);
}

.special-packages .team-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.special-packages .team-item:hover .team-overlay {
  opacity: 1;
}

/* Filter Section */
.filter-section .filter-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.filter-section .filter-group {
  display: flex;
  flex-direction: column;
}

.filter-section select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ced4da;
}

/* Testimonials Section */
.testimonials {
  background-color: #f8f9fa;
}

.testimonial-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  text-align: center;
  margin-bottom: 20px;
}

.testimonial-card p {
  font-style: italic;
}

.testimonial-card h5 {
  font-weight: bold;
  margin-top: 10px;
}

.testimonial-card small {
  color: #6c757d;
}
